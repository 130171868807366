<template>
  <div class="about">
    <MenuLeft />
  </div>
</template>

<script>
import MenuLeft from '@/components/MenuLeft';

export default {
  name: 'About',
  components: {
    MenuLeft,
  },
}
</script>

<style lang="scss" scoped>

</style>
